import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import AppsPrivacy from '../../components/apps/privacy';
import '../../styles/_base.scss';

const AppsPrivacyPolicy = () => (
  <Layout>
    <SEO title="Mevry | AI for online shops" />
    <AppsPrivacy />
  </Layout>
);

export default AppsPrivacyPolicy;
