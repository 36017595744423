import React from 'react';
import './privacy.scss';

const PrivacyPolicy = () => (
  <section className="privacy">
    <h2 className="artical-title">Privacy Policy</h2>
    <p className="artical-content">
      This Privacy Policy describes how personal information is collected, used,
      and shared when you install or use the App in connection with your
      Shopify-supported store.
    </p>
    <h3 className="artical-header">Personal Information the App Collects</h3>
    <p className="artical-content">
      When you install the App, we are automatically able to access certain
      types of information from your Shopify account: your name, address, email
      address, phone number.
    </p>
    <h3 className="artical-header">How Do We Use Your Personal Information?</h3>
    <p className="artical-content">
      We use the personal information we collect from you in order to provide
      the Service and to operate the App. Additionally, we use this personal
      information to: Communicate with you; Optimize or improve the App; and
      Provide you with information or advertising relating to our products or
      services.
    </p>
    <h3 className="artical-header">Sharing Your Personal Information</h3>
    <p className="artical-content">
      We may also share your Personal Information to comply with applicable laws
      and regulations, to respond to a subpoena, search warrant or other lawful
      request for information we receive, or to otherwise protect our rights.
    </p>
    <h3 className="artical-header">Your Rights</h3>
    <p className="artical-content">
      If you are a European resident, you have the right to access personal
      information we hold about you and to ask that your personal information be
      corrected, updated, or deleted. If you would like to exercise this right,
      please contact us through the contact information below.
    </p>
    <p className="artical-content">
      Additionally, if you are a European resident we note that we are
      processing your information in order to fulfill contracts we might have
      with you, or otherwise to pursue our legitimate business interests listed
      above. Additionally, please note that your information will be transferred
      outside of Europe, including to Canada and the United States.
    </p>
    <h3 className="artical-header">Data Retention</h3>
    <p className="artical-content">
      We will maintain your Information for contacting you with our updates
      unless and until you ask us to delete this information.
    </p>
    <h3 className="artical-header">Changes</h3>
    <p className="artical-content">
      We may update this privacy policy from time to time in order to reflect,
      for example, changes to our practices or for other operational, legal or
      regulatory reasons.
    </p>
    <h3 className="artical-header">Contact Us</h3>
    <p className="artical-content">
      For more information about our privacy practices, if you have questions,
      or if you would like to make a complaint, please contact us at{' '}
      <a href="mailto:contact@mevry.com">contact@mevry.com</a>.
    </p>
  </section>
);

export default PrivacyPolicy;
